<template>
    <BaseLayout>
        <section class="ol-product ol-product-detail">
            <div class="ol-wrapper ol-wrapper--large">

                <div class="ol-breadcrumb">
                    <router-link to="/" class="ol-breadcrumb__link">
                        Home
                    </router-link>
                    <a href="#" class="ol-breadcrumb__link">Syarat dan Ketentuan</a>
                </div>

                <div class="ol-page">
                    <h2 class="ol-page-title">SYARAT DAN KETENTUAN</h2>


                    <div class="ol-page__content">

                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><b><span lang="IN" style='font-size:15.0pt;line-height:150%;font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>Selamat Datang di morefurniture.id !</span></b><span lang="IN" style='font-size:12.0pt;
                        line-height:150%;font-family:"",serif;mso-fareast-font-family:
                        ""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>Terima kasih telah mengunjungi website kami. Sebelum berbelanja, silahkan membaca syarat dan ketentuan penggunaan website terlebih dahulu. Dengan berbelanja di website kami, berarti Anda setuju dengan syarat dan ketentuan yang telah ditentukan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:12.0pt;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>Seiring berkembangnya <b>MORE</b>, kami juga akan melakukan perubahan pada syarat dan ketentuan di website kami. Anda disarankan untuk membaca syarat dan ketentuan sebelum melakukan transaksi dengan kami.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;
                        font-family:"",serif;mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>DEFINISI DAN INTERPRETASI<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l15 level1 lfo1;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>&ldquo;<i>morefurniture.id</i>&quot;, &ldquo;<i>MORE</i>&quot;, &quot;<i>kita</i>&quot;, dan &quot;<i>kami</i>&quot; menunjuk kepada <b><i>PT Eforia Furnitur Indonesia</i></b>.  Suatu perseroan terbatas yang didirikan berdasarkan hukum Negara Republik Indonesia, yang berkedudukan hukum di Surabaya dan berdomisili usaha di Jalan  Tambak Langon Indah Nomor 2, Surabaya, Provinsi Jawa Timur.</span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'>.</span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l15 level1 lfo1;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>&quot;<i>Pelanggan</i>&quot; menunjuk kepada pembeli atau pengguna dari website</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'> </span><span lang="IN" style='font-family:
                        "",sans-serif'>layanan</span><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'> kami.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l15 level1 lfo1;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>&quot;<i>Anda</i>&quot; menunjuk kepada Pelanggan, pembeli, dan / atau individu yang menggunakan website kami.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:-27.0pt;margin-bottom:
                        0cm;margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l15 level1 lfo1;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>&quot;<i>Kekayaan</i> <i>Intelektual</i>&quot; atau &quot;<i>Hak</i> <i>Cipta</i>&quot; berarti semua paten, inovasi, merek dagang, nama domain, hak desain interior, desain terdaftar, desain produk, nama bisnis, hak melindungi reputasi, dan semua hak kepemilikan yang ada</span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'>.</span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l15 level1 lfo1;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>&quot;<i>Pesanan</i>&quot; atau &quot;<i>Order</i>&quot; berarti pesanan Anda untuk produk melalui Platform sesuai dengan Syarat &amp; Kondisi Penjualan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l15 level1 lfo1;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>&quot;<i>Data Pribadi</i>&quot; berarti data, apakah benar atau tidak, yang dapat digunakan untuk mengidentifikasi, menghubungi atau mencari Anda. Data Pribadi bisa termasuk nama, alamat email, alamat penagihan, alamat pengiriman, nomor telepon dan informasi kartu kredit. Data Pribadi akan dianggap termasuk setiap data yang Anda berikan kepada kami ketika menempatkan Order.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l15 level1 lfo1;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>&quot;<i>Email</i>&quot; mengacu pada nama identifikasi login yang mengidentifikasi Pelanggan yang memiliki akun di <b>MORE</b>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l15 level1 lfo1;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:#4472C4;mso-themecolor:accent1"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>&quot;<i>Password</i>&quot; berarti password yang valid milik Pelanggan yang memiliki </span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'>akun </span><b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>MORE</span></b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'> yang dapat digunakan dengan Username untuk mengakses website </span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:#4472C4;mso-themecolor:accent1;mso-ansi-language:EN-US'>morefurniture.id</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:#4472C4;mso-themecolor:accent1'>.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        tab-stops:list 27.0pt"><span lang="IN" style='font-size:12.0pt;line-height:150%;
                        font-family:"",serif;mso-fareast-font-family:"";
                        color:#4472C4;mso-themecolor:accent1'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        tab-stops:list 27.0pt"><span lang="IN" style='font-size:12.0pt;line-height:150%;
                        font-family:"",serif;mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>AKUN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l16 level1 lfo2;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pada saat melakukan pendaftaran akun, Pelanggan menyatakan bahwa :<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:45.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l24 level1 lfo3;tab-stops:list 45.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">a.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan adalah individu / badan hukum / badan usaha dengan legalitas sebagaimana disyaratkan oleh hukum dan peraturan yang berlaku di wilayah hukum Republik Indonesia.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:45.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l24 level1 lfo3;tab-stops:list 45.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">b.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan menyatakan bukan individu dan / atau badan hukum yang dilarang oleh hukum negara Republik Indonesia untuk menerima atau menggunakan layanan <b>MORE</b>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:45.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l24 level1 lfo3;tab-stops:list 45.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">c.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan menyatakan bahwa semua informasi dan / atau dokumen yang diisi atau disampaikan kepada <b>MORE</b> adalah akurat, benar, lengkap, terkini, dan sesuai.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:45.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l24 level1 lfo3;tab-stops:list 45.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">d.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="EN-US" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;mso-ansi-language:EN-US'>Pelanggan menyatakan bahwa dapat bertanggung jawab atas transaksi yang dilakukan di <b>MORE</b></span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l28 level1 lfo4;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan bertanggung jawab secara penuh terhadap kerahasiaan informasi akun Pelanggan, termasuk alamat email, sandi, kode voucher, membership, dan hal lainnya.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l28 level1 lfo4;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>MORE</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> berhak untuk melakukan pemeriksaan terkait kebenaran, keabsahan informasi, atau data yang diserahkan kepada <b>MORE</b>. Jika ditemukan adanya penipuan atau ketidaksesuaian berdasarkan penilaian kami, maka kami berhak mengakhiri, membatalkan, atau memblokir akses dan akun Pelanggan ke layanan <b>MORE</b>.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l28 level1 lfo4;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan dapat mengubah informasi akun seperti nama, alamat, nomor telepon, dan alamat email selama data yang diberikan tetap akurat, benar, lengkap, dan terkini.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l28 level1 lfo4;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>MORE</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> tidak memungut biaya atas pendaftaran ataupun perubahan informasi akun kepada Pelanggan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l28 level1 lfo4;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>MORE</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> berhak untuk mengirimkan email konfirmasi ke alamat email Pelanggan untuk keperluan verifikasi atau notifikasi.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;text-indent:
                        36.0pt;line-height:150%"><span lang="IN" style='font-size:12.0pt;line-height:
                        150%;font-family:"",serif;mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PENGGUNA LAYANAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l7 level1 lfo5;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan setuju untuk mematuhi setiap kebijakan, prosedur, pedoman, instruksi, dan pemberitahuan yang berkaitan dengan layanan dari <b>MORE</b>.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l7 level1 lfo5;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Akses pada layanan ini hanya diperkenankan untuk keperluan dan kepentingan berbelanja serta informasi yang terkait dengan layanan <b>MORE</b>.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l7 level1 lfo5;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>MORE</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> berhak melakukan tindakan yang dianggap tepat dan sesuai apabila terjadi penyalahgunaan layanan ataupun pelanggaran terhadap syarat dan ketentuan yang berlaku.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>AKURASI PRODUK<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l1 level1 lfo6;tab-stops:27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Kami berusaha sebaik mungkin untuk memastikan produk yang dijual di website serupa dengan foto yang terdapat di website. Akan tetapi, pengerjaan secara manual serta penggunaan material dari bahan alami dapat menyebabkan hasil yang bervariasi mulai dari bentuk permukaan serta warna, hal ini membuat setiap produk menjadi lebih unik dan memiliki ciri khas tersendiri.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l25 level1 lfo7;tab-stops:27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan memahami dan menyetujui bahwa keakuratan warna pada layar monitor dapat berbeda tergantung pada pencahayaan, pengaturan, atau resolusi pada layar monitor Pelanggan sehingga ada kemungkinan warna Produk yang diterima dapat sedikit berbeda.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l25 level1 lfo7;tab-stops:27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan memahami dan menyetujui bahwa ketersediaan Produk yang tertera pada <b>MORE</b> mungkin tidak sesuai dengan ketersediaan aktual yang disebabkan oleh beberapa hal seperti Produk rusak / cacat, kesalahan sistem, atau kesalahan teknis lainnya. Sehubungan dengan itu Pelanggan menyetujui bahwa <b>MORE</b> berhak mengalihkan, menolak, atau membatalkan Pesanan</span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'>.Terkait dengan hal ini, pihak kami akan melakukan </span><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>konfirmasi terlebih dahulu kepada pihak Pelanggan. <o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>HARGA DAN LAYANAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l30 level1 lfo8;tab-stops:27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Rincian harga termasuk ketentuan pajak yang berlaku.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;line-height:150%;tab-stops:27.0pt;
                        vertical-align:baseline"><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Semua harga yang tertera dalam website kami sudah termasuk PPN. </span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'>Pelanggan</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> tidak akan dipungut biaya ekstra untuk PPN.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l9 level1 lfo9;tab-stops:27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Harga yang tercantum dalam satuan <i>Rupiah Indonesia</i>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l9 level1 lfo9;tab-stops:27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="EN-US" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;mso-ansi-language:EN-US'>Pelanggan</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> dibebaskan dari seluruh biaya pengiriman barang.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;line-height:150%;tab-stops:27.0pt"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>Kami selalu berusaha untuk memastikan bahwa semua rincian, harga dan deskripsi yang ada di website kami selalu akurat. Jika kami menemukan kesalahan dalam harga produk yang Anda pesan, kami akan mengoreksi dan memberitahukan pada Anda.</span><span lang="IN" style='font-size:12.0pt;
                        line-height:150%;font-family:"",serif;mso-fareast-font-family:
                        ""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PEMBAYARAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>MORE</span></b><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'> bekerja sama dengan penyedia jasa perbankan / layanan pembayaran yang terpercaya dalam menyediakan berbagai metode pembayaran di <b>MORE</b> yang dapat digunakan oleh Pelanggan sesuai Syarat dan Ketentuan yang berlaku.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l2 level1 lfo10;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan menyetujui bahwa dalam melakukan Pembelanjaan, Pelanggan wajib menggunakan dan berhak memilih metode pembayaran yang tertera pada <b>MORE</b></span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'>.</span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l2 level1 lfo10;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan wajib untuk membayar penuh atas setiap Pembelanjaan yang dilakukan dalam jangka waktu yang ditentukan untuk setiap metode pembayaran yang dipilih Pelanggan. Jika Pelanggan belum melaksanakan pembayaran dalam kurun waktu yang ditentukan, maka <b>MORE</b> berhak menyatakan bahwa Pesanan telah dibatalkan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l2 level1 lfo10;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan memahami dan menyetujui jika ada diskon harga atau keuntungan lain atas pesanan yang telah dibuat tetapi tidak melakukan pembayaran sampai batas waktu yang ditentukan maka pesanan dan diskon harga tersebut akan dibatalkan<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l2 level1 lfo10;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan memahami dan menyetujui bahwa segala kerugian yang timbul akibat Pelanggan tidak mengikuti metode pembayaran yang tertera pada platform <b>MORE</b>, sepenuhnya menjadi tanggung jawab Pelanggan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l2 level1 lfo10;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan memahami apabila terjadi gangguan teknis pada metode pembayaran diluar sistem <b>MORE</b>, Pelanggan dapat melakukan konfirmasi kepada pihak perbankan / penyedia layanan pembayaran hingga status transaksi terbayarkan atau menyelesaikan transaksi dengan metode pembayaran yang lain. Selama proses pembayaran belum diterima oleh pihak <b>MORE</b>, maka kami tidak ada memproses pesanan dan pengiriman pesananan Anda.</span><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l2 level1 lfo10;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Biaya administrasi yang timbul untuk masing-masing metode pembayaran adalah tanggung jawab Pelanggan.&nbsp;<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        vertical-align:baseline"><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>Metode pembayaran yang tersedia pada <b>MORE</b> adalah :</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoListParagraphCxSpFirst" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:27.0pt;mso-add-space:auto;text-align:justify;
                        text-indent:-18.0pt;line-height:150%;mso-list:l21 level1 lfo27;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-family:Symbol;
                        mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black;
                        mso-bidi-font-weight:bold"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>E-Wallet<o:p></o:p></span></b></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:27.0pt;mso-add-space:auto;text-align:justify;
                        line-height:150%;vertical-align:baseline"><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>Pelanggan dapat menggunakan E-Wallet untuk melakukan pembelanjaan di <b>MORE</b> dengan minimum transaksi Rp10.000.<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:27.0pt;mso-add-space:auto;text-align:justify;
                        text-indent:-18.0pt;line-height:150%;mso-list:l21 level1 lfo27;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-family:Symbol;
                        mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black;
                        mso-bidi-font-weight:bold"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Virtual Account<o:p></o:p></span></b></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l27 level1 lfo29;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:
                        Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
                        mso-bidi-font-family:Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>Transfer ke virtual account dapat dilakukan melalui jaringan bank yang telah bekerja sama dengan <b>MORE</b> sesuai dengan yang tertera di Platform <b>MORE</b>.<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l27 level1 lfo29;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:
                        Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
                        mso-bidi-font-family:Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>Pembayaran dengan metode virtual account tidak dapat dilakukan melalui setor tunai di bank <o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:27.0pt;mso-add-space:auto;text-align:justify;
                        text-indent:-18.0pt;line-height:150%;mso-list:l21 level1 lfo27;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-family:Symbol;
                        mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black;
                        mso-bidi-font-weight:bold"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pembiayaan Kredit<o:p></o:p></span></b></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l27 level1 lfo29;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:Calibri;
                        mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
                        Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="EN-US" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;mso-ansi-language:EN-US'>MORE</span></b><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'> bekerja sama dengan penyedia layanan pembiayaan Kredit berbasis aplikasi untuk memudahkan proses pembayaran.</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l27 level1 lfo29;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:Calibri;
                        mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
                        Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="EN-US" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;mso-ansi-language:EN-US'>Pelanggan dapat memanfaatkan promosi, program cicilan, diskon dan voucher yang diberikan oleh penyedia layanan pembiayaan kredit selama tidak menyalahi Syarat dan Ketentuan yang berlaku. </span><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l27 level1 lfo29;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:Calibri;
                        mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
                        Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan diharuskan memiliki akun aktif pada platform yang digunakan untuk pembayaran menggunakan layanan pembiayaan Kredit </span><span lang="EN-US" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;mso-ansi-language:EN-US'>yang sudah bekerja sama dengan <b>MORE</b> </span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>( contoh : Akulaku, Kredivo, Dll )<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:7.0pt;margin-right:0cm;
                        margin-bottom:8.0pt;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l27 level1 lfo29">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
                        mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;background:white;
                        mso-highlight:white"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"Trebuchet MS";background:white;mso-highlight:white'>Kredivo menawarkan fasilitas cicilan di <b>MORE</b> tanpa kartu kredit dengan cicilan 3, 6, dan 12 bulan. Untuk detail pembiayaan kredit dengan menggunakan Kredivo, Pelanggan dapat mengakses halaman </span><span lang="IN"><a href="http://kredivo.com/faqs" target="_blank"><span style='font-family:"",sans-serif;
                        color:#4472C4;mso-themecolor:accent1;background:white'>kredivo.com/faqs</span></a></span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"Trebuchet MS";
                        background:white;mso-highlight:white'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l27 level1 lfo29;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:Calibri;
                        mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
                        Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Dipastikan Pelanggan memiliki saldo atau sisa kredit yang cukup, sesuai jumlah transaksi pesanan di <b>MORE</b>.<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l27 level1 lfo29;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:Calibri;
                        mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:
                        Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Apabila saldo atau sisa kredit pada platform pembiayaan kredit tidak mencukupi, maka kami berhak menolak proses pembayaran.&nbsp;<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:27.0pt;mso-add-space:auto;text-align:justify;
                        text-indent:-18.0pt;line-height:150%;mso-list:l21 level1 lfo27;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-family:Symbol;
                        mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black;
                        mso-bidi-font-weight:bold"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Minimarket<o:p></o:p></span></b></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l22 level1 lfo30;tab-stops:40.5pt;
                        vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:
                        Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
                        mso-bidi-font-family:Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>Proses pembayaran melalui minimarket mengikuti ketentuan dari penyedia layanan (minimarket), segala biaya administrasi yang timbul merupakan tanggung jawab Pelanggan.<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpLast" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:40.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-13.5pt;line-height:150%;mso-list:l22 level1 lfo30;tab-stops:40.5pt;
                        vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:
                        Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
                        mso-bidi-font-family:Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>Proses pembayaran melalui minimarket dianggap sah apabila sudah terbit bukti pembayaran atau nomor transaksi pembayaran dari penyedia layanan pembayaran. Apabila tidak ada bukti tersebut, maka pembayaran kami anggap tidak sah dan pesanan belum dapat kami proses.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:45.0pt;text-indent:-18.0pt;line-height:150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>-<span style="mso-spacerun:yes">&nbsp; </span>Batas waktu maksimal pembayaran pesanan Anda adalah :</span><span lang="IN" style='font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:72.0pt;text-indent:-18.0pt;line-height:150%;mso-list:l0 level1 lfo32;
                        tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">1.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Virtual Account</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> : maksimal 1 Jam dari pemesanan<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:72.0pt;text-indent:-18.0pt;line-height:150%;mso-list:l0 level1 lfo32;
                        tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">2.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Banking ( non virtual account )</span></b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'> : maksimal 1 Jam dari pemesanan<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:72.0pt;text-indent:-18.0pt;line-height:150%;mso-list:l0 level1 lfo32;
                        tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">3.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Minimarket</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> : maksimal 1 Jam dari pemesanan<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:72.0pt;text-indent:-18.0pt;line-height:150%;mso-list:l0 level1 lfo32;
                        tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">4.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>E-Wallet</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> : Direct langsung ke Login E-Wallet Anda<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:72.0pt;text-indent:-18.0pt;line-height:150%;mso-list:l0 level1 lfo32;
                        tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">5.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pembiayaan Kredit</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> : Direct langsung ke Login Pembiayaan Kredit Anda<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PENGIRIMAN PESANAN</span></b><b><span lang="EN-US" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333;mso-ansi-language:EN-US'>
                                    <o:p></o:p>
                                </span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l3 level1 lfo11;tab-stops:27.0pt list 45.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Proses pengiriman produk ready stock 7 sampai 14 hari setelah pesanan di proses.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;line-height:150%;tab-stops:27.0pt;
                        vertical-align:baseline"><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Untuk produk non ready stock , pengiriman akan dilakukan mengikuti waktu estimasi yang diberikan. Pelanggan dapat mengetahui estimasi waktu </span><span lang="IN" style='font-family:"",sans-serif'>pengiriman</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'> </span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>melalui customer service kami.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l3 level1 lfo11;tab-stops:27.0pt list 45.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pengiriman pesanan menggunakan partner ekspedisi dari pihak <b>MORE</b>. Pelanggan dapat melakukan pengecekan status pengiriman via website atau melalui layanan customer service kami.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l3 level1 lfo11;tab-stops:27.0pt list 45.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Lokasi Kota &amp; Kabupaten Yang Termasuk Coverage Area Pengiriman Kami :<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:58.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l11 level1 lfo12;tab-stops:58.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">a.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Banten<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:58.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l11 level1 lfo12;tab-stops:58.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">b.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Daerah Istimewa Yogyakarta<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:58.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l11 level1 lfo12;tab-stops:58.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">c.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>DKI Jakarta<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:58.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l11 level1 lfo12;tab-stops:58.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">d.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Jawa Barat<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:58.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l11 level1 lfo12;tab-stops:58.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">e.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Jawa Tengah<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:58.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l11 level1 lfo12;tab-stops:58.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">f.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Jawa Timur<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:58.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l11 level1 lfo12;tab-stops:58.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">g.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Bali<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l5 level1 lfo13;tab-stops:27.0pt list 45.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pengiriman hanya dilakukan di hari kerja, Senin hingga Sabtu. Tidak termasuk hari Minggu,Hari Besar dan Libur Nasional.<o:p></o:p></span></p>
                        <div style="mso-element:para-border-div;border:none;
                        mso-border-bottom-alt:solid windowtext .5pt;padding:0cm 0cm 26.0pt 0cm;
                        margin-left:9.0pt;margin-right:0cm">
                            <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l5 level1 lfo13;tab-stops:27.0pt list 45.0pt;vertical-align:baseline;
                        border:none;mso-border-bottom-alt:solid windowtext .5pt;padding:0cm;mso-padding-alt:
                        0cm 0cm 26.0pt 0cm">
                                <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;
                        mso-bidi-font-size:11.0pt;line-height:150%;font-family:Symbol;mso-fareast-font-family:
                        Symbol;mso-bidi-font-family:Symbol;color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;</span></span></span>
                                <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Perubahan waktu pengiriman dapat terjadi sewaktu-waktu berdasarkan ketersediaan atau stok produk terkait. Customer service kami akan mengkonfirmasi kepada Pelanggan apabila ada perubahan waktu pengiriman.<o:p></o:p></span></p>
                            <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l5 level1 lfo13;tab-stops:27.0pt list 45.0pt;vertical-align:baseline;
                        border:none;mso-border-bottom-alt:solid windowtext .5pt;padding:0cm;mso-padding-alt:
                        0cm 0cm 26.0pt 0cm">
                                <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;
                        mso-bidi-font-size:11.0pt;line-height:150%;font-family:Symbol;mso-fareast-font-family:
                        Symbol;mso-bidi-font-family:Symbol;color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                                <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Apabila secara tiba- tiba Anda diharuskan berada di tempat lain pada waktu pengantaran barang yang sudah dijanjikan, maka Anda dimohon untuk segera menghubungi customer service kami sehingga kami dapat mengatur jadwal pengiriman baru selambat-lambatnya 3 hari sebelum pengiriman atau segera setelah pesanan gagal terkirim. Apabila tidak ada konfirmasi ulang dalam jangka waktu maksimal 1 (satu) minggu termasuk hari Sabtu dan Minggu, maka produk yang Anda pesan akan diprioritaskan untuk Pelanggan lain</span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;mso-ansi-language:EN-US'>. </span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>
                                    <o:p></o:p>
                                </span></p>
                            <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
                        margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l5 level1 lfo13;tab-stops:27.0pt list 45.0pt;vertical-align:baseline;
                        border:none;mso-border-bottom-alt:solid windowtext .5pt;padding:0cm;mso-padding-alt:
                        0cm 0cm 26.0pt 0cm">
                                <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;
                        mso-bidi-font-size:11.0pt;line-height:150%;font-family:Symbol;mso-fareast-font-family:
                        Symbol;mso-bidi-font-family:Symbol;color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                                <!--[endif]--><span lang="IN" style='font-family:"",sans-serif'>Pelanggan dapat melakukan konfirmasi pengiriman pesanan pada Customer Service kami</span><span lang="IN" style='font-family:"",sans-serif;mso-ansi-language:EN-US'> </span><span lang="IN" style='font-family:"",sans-serif'>melalui</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> Whatsapp di nomor </span><b><span lang="IN" style='font-size:12.0pt;
                        line-height:150%;font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-color-alt:windowtext;background:white'>{{allSetting.wa_no}} </span></b><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;mso-color-alt:windowtext;
                        background:white'> </span><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>atau email </span><b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:""'>hello@</span></b><b><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        mso-ansi-language:EN-US'>morefurniture.id</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>
                                    <o:p></o:p>
                                </span></p>
                        </div>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>BIAYA PENGIRIMAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l17 level1 lfo14;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:""'>Pelanggan dibebaskan dari semua biaya pengiriman atas pesanan yang dibuat.</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        mso-ansi-language:EN-US'> </span><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:""'>Biaya pengiriman sepenuhnya menjadi tanggung jawab pihak <b>MORE</b>.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l17 level1 lfo14;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:""'>Jangkauan area pengiriman mengikuti ketentuan dari pihak <b>MORE</b>.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>INSTALASI / PERAKITAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l8 level1 lfo15;tab-stops:list 22.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pihak <b>MORE</b> menyediakan jasa </span><span lang="IN" style='font-family:"",sans-serif'>perakitan</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'> </span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>untuk produk yang perlu dirakit. Jasa ini merupakan layanan terpisah dari penjualan barang. Biaya jasa tidak termasuk dalam harga pesanan produk.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l8 level1 lfo15;tab-stops:list 22.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Untuk produk-produk tertentu yang membutuhkan proses perakitan ( kategori furnitur seperti lemari, meja rias, kabinet dll) tim kami akan datang ke rumah Anda sesuai dengan jadwal yang telah disepakati, setelah produk Anda terima. Proses pengiriman dan perakitan produk dilakukan pada hari yang berbeda<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l8 level1 lfo15;tab-stops:list 22.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Proses perakitan dilakukan pada hari Senin sampai Sabtu, tidak termasuk hari minggu, hari besar dan hari libur nasional.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:36.0pt;text-align:justify;line-height:150%;vertical-align:baseline"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <p class="MsoListParagraph" style="margin:0cm;mso-add-space:auto;text-align:justify;
                        line-height:150%;mso-outline-level:1"><b><span lang="IN" style='font-size:12.5pt;
                        line-height:150%;font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>KERUSAKAN PRODUK</span></b><b><span lang="IN" style='font-size:12.5pt;
                        line-height:150%;font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:#333333'>
                                    <o:p></o:p>
                                </span></b></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%;vertical-align:baseline"><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Kami percaya bahwa produk-produk yang ada di website kami terbuat dari bahan - bahan berkualitas tinggi. Kami akan berkoordinasi untuk memproses pengembalian produk jika produk yang Anda beli mengalami kerusakan dengan syarat dan ketentuan sebagai berikut :<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpFirst" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-18.0pt;line-height:150%;mso-list:l18 level1 lfo31;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-family:Symbol;
                        mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pastikan untuk memeriksa kondisi produk yang dibeli pada saat menerima pesanan<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-18.0pt;line-height:150%;mso-list:l18 level1 lfo31;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-family:Symbol;
                        mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Partner logistik kami akan meminta Anda untuk mengisi formulir berupa checklist detail apa saja yang perlu diperhatikan guna menjamin kondisi barang dalam keadaan sempurna sebelum Anda menandatangani penerimaan produk yang Anda pesan</span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'>.</span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-18.0pt;line-height:150%;mso-list:l18 level1 lfo31;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-family:Symbol;
                        mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Jika Anda melihat adanya kerusakan pada kemasan produk, maka Anda dimohon untuk menolak penerimaan produk pesanan dan segera menginformasikan kepada partner logistik kami</span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'>.</span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-18.0pt;line-height:150%;mso-list:l18 level1 lfo31;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-family:Symbol;
                        mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Jika kerusakan terjadi setelah Anda menerima barang dan dalam jangka waktu maksimal 2x24 jam dari pesanan diterima, segera ambil minimal 1 buah foto di area kerusakan dan 1 buah foto yang memperlihatkan produk secara keseluruhan disertai video saat unboxing pesanan.<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-18.0pt;line-height:150%;mso-list:l18 level1 lfo31;vertical-align:
                        baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-family:Wingdings;
                        mso-fareast-font-family:Wingdings;mso-bidi-font-family:Wingdings;color:black"><span style="mso-list:Ignore">&sect;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>Setelah itu Anda dapat melaporkannya kepada customer service kami melalui email :<o:p></o:p></span></p>
                        <p class="MsoListParagraphCxSpLast" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        line-height:150%;vertical-align:baseline"><span lang="IN"><a href="mailto:hello@morefurniture.id"><b><span style='font-family:"",sans-serif'>hello@</span></b><b><span lang="EN-US" style='font-family:"",sans-serif;mso-ansi-language:EN-US'>morefurniture.id</span></b></a></span><b><span lang="EN-US" style='font-family:"",sans-serif'> </span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>atau Whatsapp di nomor </span><b><span lang="IN" style='font-size:
                        12.0pt;line-height:150%;font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;mso-color-alt:windowtext;background:white'>{{allSetting.wa_no}} </span></b><b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:""'>
                                    <o:p></o:p>
                                </span></b></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PENGALIHAN &amp; PENGEMBALIAN PESANAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l26 level1 lfo18;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pengalihan pesanan terjadi apabila produk kosong atau produk ready namun tidak layak kirim ( dalam kondisi cacat atau tidak lengkap ). Pihak customer service <b>MORE</b> akan mengkonfirmasi kepada Pelanggan apabila terjadi pengalihan pesanan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l26 level1 lfo18;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan berhak melakukan penggantian pesanan apabila terjadi pengalihan pesanan sesuai kondisi di atas atau melakukan pengajuan pembatalan apabila produk yang dipesan tidak bisa dialihkan / tidak tersedia. <o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l26 level1 lfo18;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Kepuasaan Pelanggan adalah hal terpenting bagi <b>MORE</b>, sehingga kami menyediakan jasa pengiriman dan pengembalian secara gratis bekerja sama dengan partner logistik.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l26 level1 lfo18;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Apabila terjadi kesalahan pengiriman atau produk yang dikirimkan tidak sesuai dengan pesanan, Pelanggan dapat mengembalikan produk tersebut tanpa biaya restocking, dengan syarat dan ketentuan sebagai berikut :<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:40.5pt;text-align:justify;text-indent:-13.5pt;line-height:150%;
                        mso-list:l19 level1 lfo26;tab-stops:40.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
                        mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Permintaan pengembalian produk dilakukan maksimal 2 x 24 jam setelah produk diterima oleh Pelanggan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:40.5pt;text-align:justify;text-indent:-13.5pt;line-height:150%;
                        mso-list:l19 level1 lfo26;tab-stops:40.5pt;vertical-align:baseline"><a name="_Hlk72755855">
                                <!--[if !supportLists]--><span lang="IN" style="mso-ascii-font-family:
                        Calibri;mso-fareast-font-family:Calibri;mso-hansi-font-family:Calibri;
                        mso-bidi-font-family:Calibri;color:black"><span style="mso-list:Ignore">-<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                                <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>Produk harus dalam kondisi orisinil dan prima </span></a><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>disertai syarat pengajuan garansi merujuk pada pasal <b>Garansi Produk</b>. Tim kami akan melakukan analisa terlebih dahulu untuk menyatakan bahwa produk layak untuk dikembalikan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%;vertical-align:baseline"><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>GARANSI<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
                        0cm;margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l23 level1 lfo16;tab-stops:list 22.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Garansi barang berlaku untuk barang rusak atau tidak sesuai pesanan. Kategori barang rusak merujuk pada pasal <b>Kerusakan Produk</b></span><span lang="EN-US" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black;
                        mso-ansi-language:EN-US'>.</span><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l23 level1 lfo16;tab-stops:list 22.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pengajuan garansi maksimal 2 X 24 jam setelah barang diterima oleh Pelanggan, lebih dari itu kami tidak menerima komplain kerusakan, kesalahan pengiriman atau barang tidak lengkap.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l23 level1 lfo16;tab-stops:list 22.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Syarat pengajuan klaim garansi adalah adanya foto dan video saat unboxing pesanan.<o:p></o:p></span></p>
                        <p class="MsoListParagraph" style="margin-top:0cm;margin-right:0cm;margin-bottom:
                        0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;text-indent:-18.0pt;
                        line-height:150%;mso-list:l23 level1 lfo16;tab-stops:list 22.5pt">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Untuk produk yang tidak sesuai dengan pemesanan, Pelanggan harus melampirkan minimal 1 foto dan video saat produk diterima atau dibuka dalam kondisi produk belum dirakit (untuk produk rakitan), produk harus dalam kondisi orisinil dan prima.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l23 level1 lfo16;tab-stops:list 22.5pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Untuk barang yang memerlukan perakitan, garansi berlaku untuk proses perakitan yang dilakukan tim kami. Garansi tidak berlaku jika produk dirakit oleh Pelanggan atau pihak lain selain kami.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;line-height:150%;vertical-align:baseline"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PROMOSI<o:p></o:p></span></b></h1>
                        <p class="MsoListParagraphCxSpFirst" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-22.5pt;line-height:150%;mso-list:l4 level1 lfo25">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:12.0pt;line-height:150%;font-family:Symbol;mso-fareast-font-family:
                        Symbol;mso-bidi-font-family:Symbol"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>MORE</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> berhak untuk mengadakan kegiatan promosi dengan Syarat dan Ketentuan yang berbeda-beda antara satu promosi dan promosi lainnya. Pelanggan diharapkan untuk membaca secara seksama setiap Syarat dan Ketentuan yang berlaku pada promosi yang sedang berlangsung.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-22.5pt;line-height:150%;mso-list:l4 level1 lfo25">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:12.0pt;line-height:150%;font-family:Symbol;mso-fareast-font-family:
                        Symbol;mso-bidi-font-family:Symbol"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Jika terjadi kesalahan promosi, termasuk namun tidak terbatas pada gambar, spesifikasi, harga, warna, kemasan, jumlah, kuota promosi, maka <b>MORE</b> berhak membatalkan Pesanan yang terkait</span><span lang="IN" style='font-size:12.0pt;line-height:150%;
                        font-family:"",serif;mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoListParagraphCxSpMiddle" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-22.5pt;line-height:150%;mso-list:l4 level1 lfo25">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:12.0pt;line-height:150%;font-family:Symbol;mso-fareast-font-family:
                        Symbol;mso-bidi-font-family:Symbol"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Jika diduga / ditemukan indikasi pelanggaran promosi yang dilakukan Pelanggan yang merugikan <b>MORE</b>, maka kami berhak melakukan tindakan sesuai dengan yang tercantum pada pasal <b>Pelanggaran</b>.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoListParagraphCxSpLast" style="margin-top:0cm;margin-right:0cm;
                        margin-bottom:0cm;margin-left:22.5pt;mso-add-space:auto;text-align:justify;
                        text-indent:-22.5pt;line-height:150%;mso-list:l4 level1 lfo25">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:12.0pt;line-height:150%;font-family:Symbol;mso-fareast-font-family:
                        Symbol;mso-bidi-font-family:Symbol"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan menyetujui bahwa Produk yang dijual dengan potongan harga / diskon sama dengan atau lebih besar dari 70%, Produk tersebut dapat merupakan produk display / rekondisi.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>VOUCHER<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>MORE</span></b><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'> berhak menerbitkan serta menetapkan Syarat dan Ketentuan khusus yang berbeda-beda antara satu jenis voucher dan lainnya untuk promosi, pemberian hadiah, atau kepentingan lainnya, seperti :</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l20 level1 lfo17;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Terdapat voucher yang memiliki batas minimum Pembelanjaan untuk dapat digunakan dan voucher yang tidak memiliki batas minimum Pembelanjaan untuk dapat digunakan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l20 level1 lfo17;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Terdapat voucher yang dapat digunakan oleh semua akun ( dapat dipindah</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'> </span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>tangankan ) dan voucher yang tidak dapat digunakan oleh akun lainnya ( <i>account binding</i> ).<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l20 level1 lfo17;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Terdapat voucher yang dapat dikembalikan dan tidak dapat dikembalikan ( hangus ) jika terjadi pembatalan atau pengembalian </span><span lang="EN-US" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;mso-ansi-language:EN-US'>p</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>esanan.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l20 level1 lfo17;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Terdapat voucher yang dapat digabungkan dan tidak dapat digabungkan untuk penggunaannya<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-left:27.0pt;text-align:justify;text-indent:
                        -18.0pt;line-height:150%;mso-list:l20 level1 lfo17;tab-stops:list 27.0pt;
                        vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:
                        10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;font-family:Symbol;
                        mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Jenis voucher lainnya.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-left:27.0pt;text-align:justify;line-height:
                        150%;vertical-align:baseline"><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%;mso-outline-level:1"><b><span lang="IN" style='font-size:12.5pt;line-height:
                        150%;font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:#333333'>PEMBATALAN PESANAN<o:p></o:p></span></b></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l12 level1 lfo19;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pelanggan menyetujui bahwa <b>MORE</b> berhak membatalkan Pesanan Pelanggan dikarenakan situasi dan kondisi, termasuk namun tidak terbatas pada pasal <b>Pelanggaran</b> yang tertera pada Syarat dan Ketentuan ini.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l12 level1 lfo19;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pengguna dapat mengajukan pembatalan apabila pesanan belum dilakukan proses pembayaran. Apabila pesanan Pelanggan telah berhasil dibayar, maka Pelanggan tidak bisa membatalkan atau merubah pesanan.&nbsp;<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l12 level1 lfo19;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pengajuan pembatalan oleh Pengguna hanya dapat dilakukan untuk seluruh produk pada pesanan (tidak dapat batalkan untuk sebagian produk saja)<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l12 level1 lfo19;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>MORE</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> berhak menolak pengajuan pembatalan Pesanan apabila pesanan sudah dalam proses (status sudah terbayar)<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l12 level1 lfo19;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pihak <b>MORE</b> berhak melakukan pembatalan pesanan apabila dirasa terjadi kecurangan dalam proses pemesanan sehingga merugikan pihak <b>MORE</b>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PELANGGARAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:4.5pt;text-align:justify;line-height:150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>Jika diduga / ditemukan indikasi pelanggaran / penyalahgunaan yang dilakukan Pelanggan yang merugikan kami, termasuk namun tidak terbatas pada :</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l12 level1 lfo19;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Pembuatan lebih dari 1 (satu) akun oleh 1 (satu) Pelanggan yang sama; dan/atau Nomor telepon yang sama; dan/atau Alamat pengiriman yang sama; dan/atau akun login pembiayaan kredit yang sama; dan/atau Riwayat Pembelanjaan yang sama; dan/atau Manipulasi/rekayasa Pembelanjaan, promosi, dan/atau voucher.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l12 level1 lfo19;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Sehubungan dengan pasal di </span><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;mso-ansi-language:EN-US'>atas</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>, maka <b>MORE</b> berhak untuk melakukan tindakan-tindakan yang diperlukan, termasuk namun tidak terbatas pada</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'> </span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>:</span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;mso-ansi-language:EN-US'> </span><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l6 level1 lfo21;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">1.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Membatalkan Pesanan Pengguna<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l6 level1 lfo21;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">2.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Menarik promosi, diskon, cashback, voucher atau benefit lainnya<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l6 level1 lfo21;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">3.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Meminta pengembalian subsidi promosi, diskon, cashback, atau benefit lainnya yang telah diterima Pelanggan<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l6 level1 lfo21;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">4.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Tidak mengembalikan voucher yang telah digunakan<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l6 level1 lfo21;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">5.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Menutup dan menonaktifkan akun Pengguna.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:22.5pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l6 level1 lfo21;tab-stops:list 36.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:;
                        color:black'><span style="mso-list:Ignore">6.<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Menempuh jalur hukum jika merugikan secara finansial<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>KOMUNIKASI ELEKTRONIK<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;background:white'>Ketika Pelanggan menggunakan layanan <b>MORE</b></span><span lang="EN-US" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;background:white;
                        mso-ansi-language:EN-US'>, </span><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;background:white'>Pengguna setuju untuk berkomunikasi dengan kami secara elektronik seperti melalui email, telepon, media sosial, live chat, dan sebagainya.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'><br style="mso-special-character:
                        line-break">
                                <!--[if !supportLineBreakNewLine]--><br style="mso-special-character:line-break">
                                <!--[endif]-->
                                <o:p></o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>KEBIJAKAN PRIVASI<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%;tab-stops:0cm"><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;background:white'>Pengguna dapat melihat Kebijakan Privasi <b>MORE</b> yang berisi tentang cara mengumpulkan, menggunakan, atau mengungkapkan informasi pribadi Pelanggan, dan praktik perlindungan Pelanggan di Platform <b>MORE</b>. Dengan menyetujui Syarat dan Ketentuan ini, Pelanggan juga menyetujui seluruh isi Kebijakan Privasi <b>MORE</b> yang tertera di dalam Platform <b>MORE</b></span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>HAK CIPTA DAN HAK KEKAYAAN INTELEKTUAL<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l10 level1 lfo22;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Semua foto furnitur yang terdapat di website morefurniture.id adalah hak cipta <b><i>PT Eforia Furnitur Indonesia</i></b>. Karenanya, semua pihak di luar <i>PT Eforia Furnitur Indonesia</i> tidak diperkenankan mengambil / menggunakan / memperbanyak foto furnitur yang ada dalam <b>MORE</b>.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l10 level1 lfo22;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Bilamana diketahui terjadi penyalahgunaan foto milik PT Eforia Furnitur Indonesia oleh pihak manapun tanpa terkecuali, akan dikenakan sanksi.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l14 level1 lfo23;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Semua konten di website kami dimiliki oleh <b>MORE</b> dan / atau pemberi lisensinya dan dilindungi oleh hukum hak cipta. Anda tidak diperbolehkan untuk menggunakan konten website kami tanpa persetujuan tertulis dari <b>MORE</b>.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l14 level1 lfo23;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Semua konten yang dilindungi adalah teks, desain grafik, tombol ikon, gambar, foto, logo, judul kampanye, digital download dan klip audio adalah milik <b>MORE</b>. Hak cipta melindungi semua konten tersebut.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                        margin-left:27.0pt;text-align:justify;text-indent:-18.0pt;line-height:150%;
                        mso-list:l31 level1 lfo24;tab-stops:list 27.0pt;vertical-align:baseline">
                            <!--[if !supportLists]--><span lang="IN" style="font-size:10.0pt;mso-bidi-font-size:11.0pt;line-height:150%;
                        font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
                        color:black"><span style="mso-list:Ignore">&middot;<span style='font:7.0pt ""'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>
                            <!--[endif]--><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>Izin hanya akan diberikan kepada Pelanggan untuk mengunduh, mencetak atau menggunakan konten yang tersedia untuk penggunaan pribadi dan non-komersial, dengan ketentuan tidak mengubah konten <b>MORE</b> atau <i>PT Euforia Furnitur Indonesia</i> selaku pemegang semua / tiap hak cipta dan hak kekayaan intelektual lainnya yang terkandung dalam situs ini.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PENGEMBANGAN SISTEM<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;background:white'>Untuk memberikan kenyamanan lebih pada pengalaman berbelanja Pelanggan, <b>MORE</b> rutin melakukan pengembangan sistem selama beberapa waktu. Selama berjalannya proses tersebut, maka <b>MORE</b> berhak membatalkan Pesanan Pelanggan apabila ditemukan ketidaksesuaian yang dapat merugikan Pelanggan ataupun <b>MORE</b>.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PERUBAHAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>Kami setiap saat berhak untuk melakukan perubahan pada Platform, Kebijakan, serta Syarat dan Ketentuan layanan, termasuk namun tidak terbatas pada Promosi, Harga Produk, Konten, dan Biaya Layanan.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>Syarat dan Ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu. Pengguna diharapkan selalu membaca setiap Syarat dan Ketentuan yang berlaku di <b>MORE</b> dengan saksama.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PEMBEBASAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;background:white'>Jika Pelanggan memiliki perselisihan dengan satu atau lebih pihak, Pelanggan membebaskan </span><b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'>PT Eforia Furnitur Indonesia</span></b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black'> <span style="background:white">(termasuk Induk Perusahaan, Direktur, dan Karyawan) dari klaim dan tuntutan atas kerusakan dan kerugian (aktual dan tersirat) dari setiap jenis dan sifatnya, yang dikenal dan tidak dikenal, yang timbul dengan cara apapun berhubungan dengan perselisihan atau sengketa. Dengan demikian maka Pelanggan dengan sengaja membebaskan segala perlindungan hukum (yang terdapat dalam undang-undang atau peraturan hukum yang lain) yang akan membatasi cakupan ketentuan pelepasan ini.</span></span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>GANTI RUGI<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;background:white'>Pelanggan akan melepaskan <b>MORE</b> dari tuntutan ganti rugi dan menjaga<b> PT Eforia Furnitur Indonesia</b> (termasuk Induk Perusahaan, Direktur, dan Karyawan) dari setiap klaim atau tuntutan, termasuk biaya hukum yang wajar, yang dilakukan oleh pihak ketiga yang timbul dalam hal Pelanggan melanggar Syarat dan Ketentuan ini, penggunaan layanan <b>MORE</b> yang tidak semestinya dan/atau pelanggaran Pelanggan terhadap hukum atau hak-hak pihak ketiga.</span><span lang="IN" style='font-size:
                        12.0pt;line-height:150%;font-family:"",serif;mso-fareast-font-family:
                        ""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>KETIDAKABSAHAN<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;background:white'>Bilamana bagian dari Syarat dan Ketentuan ini menjadi tidak sah atau tidak dapat diberlakukan karena hukum dan peraturan yang berlaku, Pelanggan menyetujui bahwa ketidakabsahan tersebut tidak mempengaruhi bagian lain pada Syarat dan Ketentuan ini. Kami akan berusaha untuk memperbaharui ketentuan tersebut supaya menjadi sah dan tidak melanggar hukum.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;
                        font-family:"",serif;mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>TANGGUNG JAWAB</span></b><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>
                                    <o:p></o:p>
                                </span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>MORE</span></b><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'> tidak akan mengambil tanggung jawab untuk produk yang hilang, rusak dan cedera pribadi (yang diakibatkan oleh Pelanggan setelah produk diterima) yang dialami oleh Pelanggan atau pihak ketiga. <b>MORE</b> juga melepaskan tanggung jawab atas masalah yang mungkin dialami oleh Pelanggan atau pihak ketiga yang ditimbulkan secara langsung atau tidak langsungnya sewaktu Pelanggan mengakses dan menggunakan website kami. Kami tidak akan pernah melepaskan atau memberikan informasi Pelanggan kepada pihak ketiga lainnya.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PERSELISIHAN DAN PILIHAN HUKUM<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;background:white'>Syarat dan Ketentuan ini diatur dan ditafsirkan berdasarkan hukum Republik Indonesia. Segala perselisihan yang mungkin timbul antara Pelanggan dan </span><b><span lang="IN" style='font-family:
                        "",sans-serif;mso-fareast-font-family:"";color:black'>PT Eforia Furnitur Indonesia </span></b><span lang="IN" style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black;background:white'>sehubungan dengan penggunaan layanan <b>MORE</b> akan diselesaikan secara eksklusif dalam yurisdiksi pengadilan Republik Indonesia.</span><span lang="IN" style='font-size:
                        12.0pt;line-height:150%;font-family:"",serif;mso-fareast-font-family:
                        ""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>PERBEDAAN INFORMASI<o:p></o:p></span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black;background:white'>Jika terjadi adanya perbedaan Promosi, Syarat dan Ketentuan serta informasi apapun yang tertera pada Platform <b>MORE</b> dan di luar Platform <b>MORE</b>, maka Informasi, Promosi, serta Syarat dan Ketentuan yang berlaku adalah yang tertera pada Platform <b>MORE</b> (website </span><span lang="IN"><a href="http://www.morefurniture.id/"><span style='font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#1155CC;background:white'>morefurniture.id</span></a></span><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        color:black;background:white'> / aplikasi <b>MORE</b> )</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>TAUTAN KE WEBSITE</span></b><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>
                                    <o:p></o:p>
                                </span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>Anda dapat membuat link ke website kami jika Anda melakukannya secara legal dan tidak bertujuan untuk mengambil keuntungan atau merusak reputasi kami.<o:p></o:p></span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p>&nbsp;</o:p>
                            </span></p>
                        <h1 style="margin-top:0cm;text-align:justify;line-height:150%"><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:black'>PENGAKHIRAN AKUN</span></b><b><span lang="IN" style='font-size:12.5pt;line-height:150%;font-family:"",sans-serif;
                        mso-fareast-font-family:"";color:#333333'>
                                    <o:p></o:p>
                                </span></b></h1>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>Berdasarkan kebijakan kami, kami dapat memberhentikan penggunaan layanan website kami dan menonaktifkan username dan password Pelanggan dalam akun kami. Pemberhentian akun Anda dapat diberlakukan dengan alasan tertentu, salah satunya jika terjadi pelanggaran terhadap Syarat dan Ketentuan yang berlaku. Akun Anda dapat kami diberhentikan secara sepihak jika Anda telah melanggar otoritas regulasi ketentuan website kami.</span><span lang="IN" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:""'>
                                <o:p></o:p>
                            </span></p>
                        <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
                        150%"><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:
                        "";color:black'>Anda juga dapat melakukan pemberhentian akun Anda dengan memberitahukan kepada kami melalui email ke </span><b><span lang="IN" style='font-family:"",sans-serif;mso-fareast-font-family:""'>hello@</span></b><b><span lang="EN-US" style='font-family:"",sans-serif;mso-fareast-font-family:"";
                        mso-ansi-language:EN-US'>morefurniture.id</span></b><span lang="EN-US" style='font-size:12.0pt;line-height:150%;font-family:"",serif;
                        mso-fareast-font-family:"";mso-ansi-language:EN-US'>
                                <o:p></o:p>
                            </span></p><br>
                        <p></p>
                    </div>

                </div>

            </div>
        </section>
    </BaseLayout>
</template>
<script>
import BaseLayout from '@/components/landing/BaseLayout.vue';
import {mapActions,mapGetters} from "vuex";

export default {
    name:"Snk",
    metaInfo() {
        return{
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components: { 
        BaseLayout
    },
    computed:{
        ...mapGetters('home',['allSetting']),

    },
    methods:{
        ...mapActions('home',['getSetting']),

        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
    },
    async mounted(){
        await this.getSetting();
        this.jqScrolling2top();
    }
    
}
</script>
<style scoped lang="css">
</style>